import React from "react";

const MainContext = React.createContext(
  {
    user: null,
    setUser: () => {},
    hasSubscription: null,
    setHasSubscription: () => {},
    dropdown: null,
    setDropdown: () => {},
    cart: null,
    setCart: () => {},
    checkout: null,
    setCheckout: () => {},
    loginDialog: null,
    setLoginDialog: () => {},
    notificationPermissions: null,
    setNotificationPermissions: () => {},
    notificationBanner: null, 
    setNotificationBanner: () => {},
    error: null, 
    setError: () => {},
    notifications: null,
    setNotifications: () => {},
    unreadMessages: null, 
    setUnreadMessages: () => {},
    onboarding: null,
    setOnboarding: () => {},
    surveyCompleted: null,
    setSurveyCompleted: () => {}
  }
);

export default MainContext;