import { Splide, SplideSlide } from "@splidejs/react-splide"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { ReactComponent as ClockIcon } from "../assets/images/icons/ic-clock.svg"
import { ReactComponent as EmptyIllustration } from "../assets/images/illustrations/il-empty.svg"
import { Each } from "../common/Each"
import useWindowDimensions from "../common/hooks/useWindowDimensions"
import Card from "../components/cards/Card"
import ModuleCard from "../components/cards/ModuleCard"
import ProfessionalCourseCard from "../components/cards/ProfessionalCourseCard"
import EmptyContent from "../components/EmptyContent"
import LessonsCarousel from "../components/LessonsCarousel"
import TextInput from "../components/TextInput"
import typo from "../typography.module.css"
import { calcLastUpdate } from "../utils"
import styles from "./ProfessionalCourse.module.css"

const ProfessionalCourse = ({ course }) => {

  /*
      TODO 
      1. fixare edition duration
      // 2. togliere il badge "professionalizzante" dalla lesson card nel carosello e sostituirlo con il nome del modulo
      // 3. creare ModuleCard che porta al feed con url "/courses/{slug}/modules/{moduleId}"
      4. get degli annunci 
  */

  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  const [communicationsLoading, setCommunicationsLoading] = useState(false)
  const [communications, setCommunications] = useState([])
  const [lessons, setLessons] = useState([])
  const [modules, setModules] = useState([])
  const [filteredModules, setFilteredModules] = useState([])

  const splideRef = useRef(null)

  useEffect(() => {
    const lessons = []
    const { edition, ...c } = course
    const { modules, ...e } = edition

    const mappedModules = modules.map(module => {
      const { lessons: ml, ...m } = module
      return { ...m, course: c, edition: e }
    })
    setFilteredModules(mappedModules)
    setModules(mappedModules)

    for (const module of modules) {
      const { lessons: moduleLessons, ...m } = module
      for (const lesson of moduleLessons) {
        const l = {
          ...lesson,
          edition: {
            ...e,
            course: c,
            module: m,
          }
        }
        lessons.push(l)
      }
    }
    setLessons(lessons)

    const communications = []
    for (const module of modules) {
      for (const communication of module.activities) {
        communications.push({ id: module.id, name: module.name, ...communication })
      }
    }
    setCommunications(communications)
  }, [course])

  const onSearch = (searchText) => {
    if (searchText) {
      searchText = searchText.toLowerCase()
      const filtered = modules.filter(m => {
        return m.name.toLowerCase().trim().includes(searchText) ||
          m.description.toLowerCase().trim().includes(searchText) ||
          m.teachers.some(t => `${t.name} ${t.surname}`.toLowerCase().trim().includes(searchText))
      }
      )
      setFilteredModules(filtered)
    } else {
      setFilteredModules(modules)
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.sectionInner}>
          <div className={typo.title}>{t("myPath.professionalCourse")}</div>

          <div className={styles.columns}>
            <div className={styles.courseCard}>
              {
                course &&
                <ProfessionalCourseCard course={course} />
              }
            </div>
            <div className={styles.communications}>
              <Card style={{ height: "100%", width: "100%" }}>
                <div className={styles.cardContent} style={{ flexGrow: 1 }}>
                  <div className={styles.cardTitleContainer}>
                    <div className={styles.cardTitle}>{t("myPath.communications")}</div>
                  </div>
                  {
                    !communicationsLoading && communications.length > 0 &&
                    <Splide
                      id={styles.communications}
                      ref={splideRef}
                      aria-label="Communications" options={{
                        rewind: true,
                        autoplay: true,
                        speed: 1000,
                        gap: '1rem'
                      }}>
                      <Each
                        of={communications}
                        render={(activity) => (
                          <SplideSlide>
                            <div className={styles.activity}>
                              <div className={styles.activityHeader}>
                                {/* <BackofficeIcon className={styles.backoffice} /> */}
                                <div className={styles.activityTitle}>
                                  <img
                                    className={styles.profilePic}
                                    src={activity.profile.picture}
                                    alt={`${activity.profile.surname}`}
                                  />
                                  <div className={styles.activityInfo}>
                                    <div className={styles.profile}>{activity.profile.name} {activity.profile.surname}</div>
                                    <div className={styles.clock}><ClockIcon />{calcLastUpdate(activity.created_at, width > 768 ? false : true, true)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={styles.activityContent}>
                                {activity.info?.message ?? ""}
                              </div>
                            </div>
                          </SplideSlide>
                        )}
                      />
                    </Splide>
                  }
                  {
                    !communicationsLoading && communications.length === 0 &&
                    <div className={styles.noCommunications}>
                      <EmptyContent
                        Illustration={EmptyIllustration}
                        title={"Nessuna comunicazione"}
                        style={{ width: "100%" }}
                      />
                    </div>

                  }
                </div>
              </Card>
            </div>
          </div>

          <LessonsCarousel lessons={lessons} courses={[course]} showModule />

          <div className={styles.header}>
            <div className={styles.sectionTitle}>{t("myPath.modules")}</div>
            <div className={styles.searchBar}>
              <TextInput
                onKeyUp={onSearch}
                type="search"
                placeholder={t("search")}
                style={{
                  backgroundColor: "var(--background-secondary-color)",
                }} />
            </div>
          </div>
          <div className={styles.modules}>
            <Each
              of={filteredModules}
              render={module => (
                <ModuleCard module={module} />
              )}
            />
          </div>

        </div>
      </div>
    </div>
  )
}

export default ProfessionalCourse
