import { GoogleAuthProvider, OAuthProvider, createUserWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import api from "../api";
import { auth } from "../firebase";
import { ReactComponent as LogoBlack } from '../assets/images/velv-black.svg';
import { ReactComponent as GoogleLogo } from '../assets/images/google.svg';
import { ReactComponent as AppleLogo } from '../assets/images/apple.svg';
import style from "./Login.module.css"
import typo from "../typography.module.css"
import TextInput from "../components/TextInput";
import Button from "../components/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FooterLayout from "../components/layouts/FooterLayout";
import MainContext from "../common/MainContext";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { Splide, SplideSlide } from "@splidejs/react-splide"
import Screenshot1 from "../assets/images/login1.png"
import Screenshot2 from "../assets/images/login2.png"
import Screenshot3 from "../assets/images/login3.png"
import { isEmptyNullUndefined } from "../utils";
import ComputerSearch from "../assets/images/illustrations/il-computer-search.png"
import Books from "../assets/images/illustrations/il-books.png"

const Signup = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const context = useContext(MainContext)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const [error, setError] = useState(null)

    const googleProvider = new GoogleAuthProvider()
    googleProvider.setCustomParameters({
        // Localize the Apple authentication screen in Italian.
        locale: 'it'
    });

    const appleProvider = new OAuthProvider('apple.com')
    appleProvider.addScope('email');
    appleProvider.addScope('name');
    appleProvider.setCustomParameters({
        // Localize the Apple authentication screen in Italian.
        locale: 'it'
    });

    useEffect(() => {
        window.scrollTo({
            top: 0,
        })
    }, [])

    useEffect(() => {
        if (auth.currentUser) {
            if (!auth.currentUser.isAnonymous) {
                navigate('/')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.currentUser])

    const googleSignIn = async () => {
        try {
            await signInWithPopup(auth, googleProvider)
            let user = await api.get("/user")
            if (user) {
                await updateFromLocalStorage(user)
                navigate("/")
            }
        }
        catch (error) {
            console.error("Error Google Sign In: ", error)
            setError({ code: -1, message: error.message })
        }
    }

    const appleSignIn = async () => {
        try {
            await signInWithPopup(auth, appleProvider)
            let user = await api.get("/user")
            if (user) {
                await updateFromLocalStorage(user)
                navigate("/")
            }
        }
        catch (error) {
            console.error("Error Apple Sign In: ", error)
            setError({ code: -1, message: error.message })
        }
    }

    const signUp = async () => {
        try {
            if (!checkPasswords()) {
                return
            }
            await createUserWithEmailAndPassword(auth, email, password)
            let user = await api.get("/user")
            if (user) {
                await updateFromLocalStorage(user)
                navigate("/")
            }
        }
        catch (error) {
            if (error.message.includes('already-in-use')) {
                setError({ code: -1, message: "Questo utente è già registrato." })
            }
            else {
                setError({ code: -1, message: error.message })
            }
        }
    }

    const onEmailChange = (value) => {
        setEmail(value)
        if (!validateEmail(value)) {
            setError({ code: -1, message: t('errors.invalidEmail') })
        }
        else {
            setError(null)
        }

    }

    const onPasswordChange = (value) => {
        setPassword(value)
        setError(checkPasswords() ? null : { code: -1, message: t('errors.passwordNotEquals') })
    }

    const onPasswordRepeatChange = (value) => {
        setRepeatPassword(value)
        setError(checkPasswords() ? null : { code: -1, message: t('errors.passwordNotEquals') })
    }

    const validateEmail = (email) => {
        if (email.length > 0) {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        }
        else {
            return true
        }
    }

    const checkPasswords = () => {
        return (password.length > 0 && repeatPassword.length > 0) ? password === repeatPassword : true
    }

    const goToLogin = () => {
        navigate('/login')
    }

    useEffect(() => {
        window.addEventListener("keydown", handleEnter)
        return () => {
            window.removeEventListener("keydown", handleEnter)
        }
    }, [email, password, repeatPassword])

    const handleEnter = (e) => {
        if (e.code === "Enter") {
            signUp()
        }
    }

    const updateFromLocalStorage = async (user) => {
        try {
            if (localStorage.getItem('surveyId') &&
                localStorage.getItem('surveyContent') &&
                localStorage.getItem('surveyUserAnswers')) {
                    
                let surveyId = parseInt(localStorage.getItem('surveyId'))
                let surveyContent = JSON.parse(localStorage.getItem('surveyContent'))
                let surveyLength = surveyContent.filter(q => q.required !== false).length
                let surveyAnswers = JSON.parse(localStorage.getItem('surveyUserAnswers'))
                if (!isNaN(surveyId)) {
                    let userAnswers = JSON.parse(localStorage.getItem('surveyUserAnswers'))
                    if (!user.mandatory_survey || surveyAnswers.length >= surveyLength) {
                        const data = { surveyId, userAnswers }
                        await postSurvey(data)
                        context.setSurveyCompleted(true)
                        localStorage.removeItem('surveyId')
                        localStorage.removeItem('surveyContent')
                    }
                }
            }
            if (localStorage.getItem('userData')) {
                let userData = JSON.parse(localStorage.getItem('userData'))

                console.log(!isEmptyNullUndefined(userData.name),
                    !isEmptyNullUndefined(userData.surname),
                    !isEmptyNullUndefined(userData.age), !isNaN(parseInt(userData.age)),
                    !isEmptyNullUndefined(userData.city),
                    !isEmptyNullUndefined(userData.gender))

                if (!isEmptyNullUndefined(userData.name) &&
                    !isEmptyNullUndefined(userData.surname) &&
                    !isEmptyNullUndefined(userData.age) && !isNaN(parseInt(userData.age)) &&
                    !isEmptyNullUndefined(userData.city) &&
                    !isEmptyNullUndefined(userData.gender)) {
                    await updateUser(userData)
                }
            }
        }
        catch (e) {
            console.error(e)
            context.setUser(user)
        }
        navigate('/')
    }

    const updateUser = async (data) => {
        try {
            const formData = new FormData();
            formData.append("name", data.name);
            formData.append("surname", data.surname);
            formData.append("age", parseInt(data.age));
            formData.append("city", data.city);
            formData.append("gender", data.gender);

            const user = await api.put("/user", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            if (user) {
                context.setUser(user)
                localStorage.removeItem('userData')
            }

        }
        catch (e) {
            console.error(e)
        }
    }

    const postSurvey = async (data) => {
        try {
            await api.post(`/survey/${data.surveyId}`, {
                content: { content: data.userAnswers }
            })
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <FooterLayout>
            <HelmetProvider>
                <Helmet>
                    <title>Registrati</title>
                </Helmet>
            </HelmetProvider>
            <div className={style.container}>
                <>
                    <div className={style.columnLeft}>
                        <div className={style.logoContainer}>
                            <LogoBlack className={style.logo} onClick={() => { navigate("/") }} />
                        </div>
                        <div className={style.innerLeft}>

                            <div className={style.spacer}></div>
                            <div className={[typo.title, style.title].join(' ')}>{t('signup.title')}</div>
                            <div className={[typo.body, style.body].join(' ')}>{t('signup.subtitle')}</div>

                            <div className={style.formContainer}>
                                <TextInput type="text" placeholder={t('signup.email')} onKeyUp={onEmailChange} autocomplete="off"></TextInput>
                                <TextInput type="password" placeholder={t('signup.password')} onKeyUp={onPasswordChange} autocomplete="new-password"></TextInput>
                                <TextInput type="password" placeholder={t('signup.repeatPassword')} onKeyUp={onPasswordRepeatChange} autocomplete="new-password"></TextInput>

                                <div className={style.error}>
                                    {error &&
                                        <span className={typo.caption}>
                                            {error.message}
                                        </span>
                                    }
                                </div>
                            </div>

                            <div className={typo.caption}>
                                Registrandoti dichiari di aver letto e di accettare la nostra <a style={{ textDecoration: 'underlined', color: 'var(--primary)' }} href="https://shop.startingfinance.com/pages/privacy-policy" target="_blank">privacy policy</a>
                            </div>

                            <Button onClick={signUp} style={{ padding: '1rem 1.5rem' }} fullWidth>{t('signup.register').toUpperCase()}</Button>

                            <div className={style.textDivider}>
                                <div className={style.divider}></div>
                                <div className={style.caption}>{t('signup.or')}</div>
                                <div className={style.divider}></div>
                            </div>

                            <div className={style.socialLogin}>
                                <button className={style.googleSignIn} onClick={googleSignIn}>
                                    <GoogleLogo className={style.googleLogo}></GoogleLogo>
                                    {t('signup.signUpWithGoogle')}
                                </button>
                                <button className={style.appleSignIn}>
                                    <AppleLogo className={style.appleLogo} onClick={appleSignIn}></AppleLogo>
                                    {t('signup.signUpWithApple')}
                                </button>
                            </div>
                            <div className={style.spacer}></div>
                            <div className={style.registerContainer}>
                                {t('signup.haveAccount')}
                                <Button appearance="text" onClick={goToLogin}>{t('signup.login')}</Button>
                            </div>

                        </div>

                    </div>
                    <div className={style.columnRight}>
                        <div className={style.innerRight}>
                            <Splide>
                                <SplideSlide className={style.slide}>
                                    <div className={[style.rightTitle, typo.title].join(' ')}>{t('signup.contentTitle')}</div>
                                    <div className={[typo.body, style.rightBody].join(' ')}>{t('login.contentSubtitle')}</div>
                                    <img src={Books} className={style.screenshot} alt="" />
                                </SplideSlide>
                                <SplideSlide className={style.slide}>
                                    <div className={[style.rightTitle, typo.title].join(' ')}>Il tuo percorso di carriera inizia qui</div>
                                    <div className={[typo.body, style.rightBody].join(' ')}>Partecipa alle masterclass e ai corsi professionalizzanti tenuti da esperti del settore per costruire la carriera dei tuoi sogni.</div>
                                    <img src={ComputerSearch} className={style.screenshot} alt="" />
                                </SplideSlide>
                                {/* <SplideSlide className={style.slide}>
                                    <div className={[style.rightTitle, typo.title].join(' ')}>Tutto l'aiuto di cui hai bisogno</div>
                                    <div className={[typo.body, style.rightBody].join(' ')}>Resta in contatto diretto con i docenti e i tutor per qualsiasi necessità tramite la live chat.</div>
                                    <img src={Screenshot3} className={style.screenshot} alt="" />
                                </SplideSlide> */}
                            </Splide>
                        </div>
                    </div>
                </>
            </div>
        </FooterLayout>
    )
}

export default Signup