import { ReactComponent as Logo } from "../../assets/images/velv-minimized.svg"
import Button from "../Button"
import style from "./Footer.module.css"

const Footer = () => {
    return (
        <div className={style.container}>
            <div className={style.info}>
                <Logo className={style.logo} />
                <div className={style.company}>
                    <div className={style.copyright}>Copyright ©2024 Starting Finance S.r.l.</div>
                    <div className={style.vatNumber}>Partita IVA: 14944641001</div>
                </div>
            </div>
            <div className={style.links}>
                <a href="mailto:info@startingfinance.com">
                    <Button appearance="text"> Contatti </Button>
                </a>
                <Button appearance="text" onClick={() => {
                    window.open("https://shop.startingfinance.com/pages/privacy-policy", "_blank")
                }}>Privacy Policy</Button>
            </div>
        </div>
    )
}

export default Footer
