import styles from "./ModuleFeed.module.css"
import typo from "../typography.module.css"
import { useCallback, useEffect, useRef, useState } from "react"
import api from "../api"
import CourseBadge from "./CourseBadge"
import { Each } from "../common/Each"
import { calcLastUpdate, dropDuplicates, formatTimeV2, getDayName, getMonthName } from "../utils"
import Button from "./Button"
import { ReactComponent as ChevronIcon } from "../assets/images/icons/ic-chevron.svg"
import { ReactComponent as TutorIcon } from "../assets/images/icons/ic-tutor.svg"
import LessonPlaceholder from "../assets/images/placeholders/lesson-placeholder.png"
import { FeedType, LessonStatus } from "../common/constants"
import FeedCard from "./cards/FeedCard"
import { Helmet, HelmetProvider } from "react-helmet-async"
import MaterialButton from "./MaterialButton"
import { useNavigate, useParams } from "react-router-dom"
import TeachersBadge from "./TeachersBadge"
import Skeleton from "./Skeleton"
import { ReactComponent as ArrowIcon } from "../assets/images/icons/ic-arrow.svg"
import TutorsBadge from "./TutorsBadge"
import HeaderFooterLayout from "./layouts/HeaderFooterLayout"

const ModuleFeed = () => {

    const { moduleId, slug } = useParams()
    const [course, setCourse] = useState(null)
    const [loadingCourse, setLoadingCourse] = useState(true)
    const [teachers, setTeachers] = useState([])
    const [loadingTeachers, setLoadingTeachers] = useState(true)
    const [lessons, setLessons] = useState(null)
    const [nextLessons, setNextLessons] = useState([])
    const [showLessons, setShowLessons] = useState(false)
    const [stories, setStories] = useState([])
    const [loadingStories, setLoadingStories] = useState(true)
    const [feed, setFeed] = useState([{ loading: true }])
    const [endOfFeed, setEndOfFeed] = useState(false)
    const [loadingFeed, setLoadingFeed] = useState(false)
    const [lastUpdate, setLastUpdate] = useState('')
    const [materials, setMaterials] = useState([])
    const [feedMaterials, setFeedMaterials] = useState([])
    const navigate = useNavigate();

    const endFeedRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const [showMaterials, setShowMaterials] = useState(false)
    const [lastMaterialUpdate, setLastMaterialUpdate] = useState(null)

    useEffect(() => {
        const getFeedMaterials = async (module_id) => {
            try {
                let fm = await api.get(`/modules/${module_id}/feed/materials`)
                setFeedMaterials(fm)
            }
            catch (e) {
                console.error(e)
            }
        }

        if (showMaterials && course) {
            getFeedMaterials(course.edition.modules[0].id)
        }
    }, [showMaterials])

    useEffect(() => {
        const handleScroll = () => {

            if (endFeedRef.current) {
                const rect = endFeedRef.current.getBoundingClientRect();
                const isVisible = (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
                setIsVisible(isVisible);
            }
        };

        window.addEventListener('scroll', handleScroll);
        // Initial check on component mount
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (isVisible && course && !loadingFeed && !endOfFeed) {
            getFeed(course.edition.modules[0].id);
        }
    }, [isVisible])

    useEffect(() => {
        const getEditionByCourseSlug = async () => {
            setLoadingCourse(true)

            try {
                let course = await api.get(`user/courses/${slug}`)
                setCourse(course)
            }
            catch (e) {
                console.error(e)
            }
            setLoadingCourse(false)
        }

        const getCourseByModuleId = async () => {
            setLoadingCourse(true)
            try {
                const course = await api.get(`user/courses/${slug}/modules/${moduleId}`)
                setCourse(course)
            }
            catch (e) {
                console.error(e)
            }
            setLoadingCourse(false)
        }

        if (slug && !course) {
            getEditionByCourseSlug()
        }

        if (moduleId) {
            getCourseByModuleId()
        }

    }, [slug, moduleId])

    useEffect(() => {

        const getTeachers = async () => {
            if (teachers.length > 0) {
                return
            }

            setLoadingTeachers(true)
            try {
                let teachers = await api.get(`/editions/${course.edition.id}/teachers`)
                setTeachers(teachers)
            }
            catch (e) {
                console.error(e)
            }
            setLoadingTeachers(false)
        }

        const getLessons = async () => {
            if (lessons) {
                return
            }
            try {
                setLoadingStories(true)
                let lessons = await api.get(`/modules/${course.edition.modules[0].id}/lessons`)
                let stories = lessons.map((l) => {

                    let lessonStart = new Date(l.starts_at)
                    let lessonEnd = new Date(l.ends_at)
                    let now = new Date()

                    let offset = now.getTimezoneOffset();

                    lessonStart = new Date(lessonStart.getTime() - (offset * 60 * 1000));
                    lessonEnd = new Date(lessonEnd.getTime() - (offset * 60 * 1000));

                    var status = LessonStatus.ToStart
                    if (lessonStart < now && lessonEnd > now) {
                        status = LessonStatus.Live
                    }
                    else if (lessonEnd < now) {
                        status = LessonStatus.Ended
                    }

                    l.status = status
                    return l
                }).sort((a, b) => {
                    // Ordina per status
                    const statusComparison = LessonStatus.All.indexOf(a.status) - LessonStatus.All.indexOf(b.status);
                    if (statusComparison !== 0) {
                        return statusComparison;
                    }
                    // Se lo status è uguale, ordina per ends_at
                    return (new Date(a.starts_at)) - (new Date(b.starts_at));
                })
                let nextLessons = lessons.filter((l) => { return new Date(l.starts_at) > new Date() })
                    .sort((a, b) => new Date(b.starts_at) - new Date(a.starts_at));

                setStories(stories)
                setLessons(lessons)
                setNextLessons(nextLessons)
            }
            catch (e) {
                console.error(e)
            }
            setLoadingStories(false)
        }

        const getMaterials = async (module_id) => {
            try {
                let materials = await api.get(`/modules/${module_id}/materials`)
                setMaterials(materials)

                const recent = materials.reduce((prev, current) => (prev.created_at > current.created_at) ? prev : current);
                setLastMaterialUpdate(calcLastUpdate(recent.created_at, false, true))
            }
            catch (e) {
                console.error(e)
            }
        }

        if (course) {
            getTeachers()
            getLessons()

            let module_id = course.edition.modules[0].id
            getFeed(module_id)
            getMaterials(module_id)
        }

    }, [course])

    const getFeed = useCallback(async (module_id) => {
        setLoadingFeed(true)
        try {
            console.time("feed")
            let lastPriority = null
            if (feed && feed.length > 0) {
                lastPriority = feed[feed.length - 1].priority
            }
            setFeed(f => {
                return [...f.filter(f => !f.loading), { loading: true }]
            })

            let newFeed = await api.get(`/modules/${module_id}/feed${lastPriority ? "?cursor=" + lastPriority : ""}`)
            if (newFeed && newFeed.length > 0) {
                const recent = newFeed.reduce((prev, current) => (prev.last_update > current.last_update) ? prev : current);
                if (!lastUpdate) {
                    setLastUpdate(calcLastUpdate(recent.last_update, false, true))
                }

                setFeed((p) => {
                    return dropDuplicates([...p, ...newFeed].filter(f => !f.loading))
                })
            } else {
                setEndOfFeed(true)
                setFeed(p => p.filter(f => !f.loading))
            }
            console.timeEnd("feed")
        }
        catch (e) {
            console.error(e)
        }
        setLoadingFeed(false)
    }, [feed])

    const onFeedSubmit = useCallback(async (index) => {
        const activity = feed[index]

        setFeed((pf) => {
            pf[index].loading = true
            return [...pf]
        })

        try {
            const newActivity = await api.get(`/activities/${activity.id}`)
            setFeed((pf) => {
                pf[index] = newActivity
                return [...pf]
            })
        } catch (e) {
            console.error(e)
            setFeed((pf) => {
                pf[index].loading = false
                return [...pf]
            })
        }
    }, [feed])

    const pageContent = (
        <div className={styles.container}>
            <>
                <HelmetProvider>
                    <Helmet>
                        <title>{course?.name ?? "Corso"}</title>
                    </Helmet>
                </HelmetProvider>
                <div className={styles.feed}>
                    <div className={styles.section}>
                        {!showMaterials &&
                            <div className={styles.sectionInner}>
                                {
                                    loadingCourse === true &&
                                    <div className={styles.header} style={{ gap: "1rem" }}>
                                        <Skeleton type="rect" width="50%" height={"32px"} borderRadius={"12px"} />
                                        <Skeleton type="rect" width="30%" height={"14px"} borderRadius={"12px"} />
                                    </div>
                                }
                                {
                                    !loadingCourse &&
                                    <div className={styles.header}>
                                        <div className={[typo.title, styles.courseName].join(' ')}>
                                            {course.name} - {course.edition.name}
                                        </div>
                                        <div className={styles.lastUpdate}>
                                            Ultimo aggiornamento <strong>{lastUpdate}</strong>
                                        </div>
                                    </div>
                                }
                                {
                                    (loadingCourse || loadingStories) &&
                                    <div className={styles.stories}>
                                        <Each of={[0, 1]} render={(s) => (
                                            <div className={styles.story}>
                                                <Skeleton type="circle" width={"80px"} height={"80px"} />
                                                <Skeleton type="rect" width={"72px"} height={"20px"} borderRadius={"12px"} />
                                            </div>
                                        )} />
                                    </div>
                                }

                                {!loadingStories && stories.length > 0 &&
                                    <div className={styles.stories}>
                                        <div className={styles.storiesTrack}>
                                            <Each of={stories} render={(s) => {
                                                var style = styles.ended
                                                switch (s.status) {
                                                    case LessonStatus.ToStart:
                                                        style = styles.toStart
                                                        break
                                                    case LessonStatus.Live:
                                                        style = styles.live
                                                        break
                                                    case LessonStatus.Ended:
                                                        style = styles.ended
                                                        break
                                                    default: style = styles.ended
                                                }
                                                return (
                                                    <div className={styles.story} onClick={() => { navigate(`/lessons/${s.room_name}`) }}>
                                                        <img className={`${styles.storyThumbnail} ${style}`} src={s.thumbnail ? s.thumbnail : LessonPlaceholder} alt={`${s.name}`} />
                                                        <div className={styles.storyName}>
                                                            {s.name}
                                                        </div>
                                                    </div>
                                                )
                                            }} />
                                        </div>
                                    </div>
                                }
                                {feed.length > 0 &&
                                    <>
                                        <Each of={feed} render={(element, index) => {
                                            return (
                                                <FeedCard activity={element}
                                                    onSubmit={() => onFeedSubmit(index)} />
                                            )
                                        }} />
                                        <div ref={endFeedRef} style={{ visibility: 0 }} />
                                    </>
                                }
                            </div>
                        }
                        {showMaterials &&
                            <div className={styles.sectionInner}>
                                <div className={styles.header}>
                                    <Button
                                        appearance="text"
                                        style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}
                                        onClick={() => { setShowMaterials(false) }}
                                    >
                                        <ArrowIcon style={{ width: '12px', height: '12px' }} />
                                        Torna Indietro
                                    </Button>
                                </div>
                                <div className={styles.header}>
                                    <div className={typo.title}>
                                        Materiali didattici
                                    </div>
                                    <div className={styles.lastUpdate}>
                                        Ultimo upload <strong>{lastMaterialUpdate}</strong>
                                    </div>
                                </div>
                                {feedMaterials.length > 0 &&
                                    <>
                                        <Each of={feedMaterials} render={(element, index) => {
                                            return (
                                                <>
                                                    {element.type === FeedType.Material &&
                                                        <FeedCard activity={element}
                                                            onSubmit={() => onFeedSubmit(index)} />
                                                    }
                                                </>
                                            )
                                        }} />
                                    </>
                                }

                            </div>
                        }
                    </div>
                </div>

                <div className={styles.toolbarContainer}>
                    <div className={styles.toolbar}>
                        {
                            loadingCourse &&
                            <div className={styles.course}>
                                <CourseBadge loading />
                                <div style={{ display: "flex", flexDirection: "column", gap: ".2rem" }}>
                                    <Skeleton type="rect" width="100%" height={"18px"} borderRadius={"6px"} />
                                    <Skeleton type="rect" width="50%" height={"18px"} borderRadius={"6px"} />
                                </div>

                            </div>
                        }
                        {
                            !loadingCourse &&
                            <div className={styles.course}>
                                <CourseBadge type={course.type} style={{ width: 'fit-content' }} />
                                <div className={typo.body}>
                                    {course.edition.description ?? course.description}
                                </div>
                            </div>
                        }
                        <div className={styles.divider} />
                        <TeachersBadge
                            loading={loadingTeachers}
                            centered
                            showQualification
                            teachers={teachers}
                            pictureStyle={{ width: "48px", height: "48px" }}
                            qualificationStyle={{ fontSize: ".865rem" }}
                        />
                        <div className={styles.divider} />
                        {nextLessons.length > 0 &&
                            <>
                                <div className={styles.nextLessons}>
                                    <div className={typo.subtitle} style={{ fontSize: '1rem', paddingBottom: '.25rem' }}>In programma</div>
                                    <Each of={nextLessons} render={(lesson, index) => {
                                        return (
                                            <div className={styles.nextLesson}>
                                                <div className={styles.nextLessonDate}>
                                                    <div className={styles.nextLessonMonth}>
                                                        {getMonthName(lesson.starts_at, true).toUpperCase()}
                                                    </div>
                                                    <div className={styles.nextLessonDay}>
                                                        {new Date(lesson.starts_at).getDate()}
                                                    </div>
                                                </div>
                                                <div className={styles.nextLessonInfo}>
                                                    <div className={styles.nextLessonWeekDay}>
                                                        {getDayName(lesson.starts_at)}, {lesson.name}
                                                    </div>
                                                    <div className={styles.nextLessonHours}>
                                                        {formatTimeV2(lesson.starts_at)} - {formatTimeV2(lesson.ends_at)}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }} />
                                </div>
                                <div className={styles.divider} />
                            </>

                        }
                        {
                            loadingStories &&
                            <>
                                <div className={styles.lessons}>
                                    <Skeleton type="rect" width="114px" height={"18px"} borderRadius={"6px"} />
                                    <Each of={[80, 40, 60]} render={(l) => {
                                        return (
                                            <div className={styles.lesson}>
                                                <Skeleton type="rect" width={`${l}%`} height={"18px"} borderRadius={"6px"} />
                                            </div>
                                        )
                                    }} />
                                </div>
                                <div className={styles.divider} />
                            </>
                        }

                        {!loadingStories && lessons && lessons.length > 0 &&
                            <>
                                <div className={styles.lessons}>
                                    <div className={typo.subtitle} style={{ fontSize: '1rem', paddingBottom: '.25rem' }}>Le lezioni</div>
                                    <Each of={showLessons ? lessons : lessons.slice(0, 2)} render={(lesson) => {
                                        return (
                                            <div className={styles.lesson} onClick={() => navigate(`/lessons/${lesson.room_name}`)}>
                                                {lesson.name}
                                            </div>
                                        )
                                    }} />
                                    {lessons.length > 2 &&
                                        <Button appearance="text"
                                            style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: '0.875rem', gap: '0.5rem' }}
                                            onClick={() => {
                                                setShowLessons(!showLessons)
                                            }}>
                                            {showLessons ? "mostra meno" : "mostra tutte"} <ChevronIcon className={showLessons ? styles.chevronUp : styles.chevronDown} />
                                        </Button>
                                    }
                                </div>
                                <div className={styles.divider} />
                            </>
                        }
                        {
                            !loadingCourse &&
                            <>
                                <TutorsBadge tutors={teachers.filter(t => t.role === 'tutor')} />
                                <div className={styles.divider} />
                            </>
                        }

                        {materials && materials.length > 0 &&
                            <div className={styles.materials}>
                                <div className={typo.subtitle} style={{ fontSize: '1rem', paddingBottom: '.25rem' }}>Materiali didattici</div>
                                <Each of={materials.slice(0, 2)} render={(material) => {
                                    return (
                                        <MaterialButton material={material} />
                                    )
                                }} />
                                <Button onClick={() => { setShowMaterials(true) }} appearance="text" style={{ fontSize: '0.875rem' }}>vedi tutti</Button>
                            </div>
                        }
                    </div>
                </div>
            </>
        </div>
    )

    return (
        <>
            {
                moduleId &&
                <HeaderFooterLayout>
                    {pageContent}
                </HeaderFooterLayout>
            }
            {
                !moduleId &&
                pageContent 
            }
        </>

    )
}

export default ModuleFeed
