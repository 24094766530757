
import { useEffect, useState } from "react"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import styles from "./Coupon.module.css"
import api from "../api"
import typo from "../typography.module.css"
import CouponIllustration from "../assets/images/illustrations/il-coupon.png"
import { ReactComponent as CopyIcon } from "../assets/images/icons/ic-copy.svg"
import { ReactComponent as CheckIcon } from "../assets/images/icons/ic-check.svg"

import Button from "../components/Button"

const Coupon = () => {

    const [surveyCompleted, setSurveyCompleted] = useState(false)
    const [copied, setCopied] = useState(false)

    useEffect(() => {
        const getSurvey = async () => {
            try {
                let survey = await api.get("/user/survey")
                if (survey) {
                    setSurveyCompleted(true)
                }
                else {
                    setSurveyCompleted(false)
                }
            }
            catch (e) {
                setSurveyCompleted(false)
                console.error(e)
            }
        }

        getSurvey()
    }, [])

    const copyInClipboard = () => {
        navigator.clipboard.writeText("COUPONCODE")
        setCopied(true)
    }

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false)
            }, 1000)
        }
    }, [copied])

    return (
        <HeaderFooterLayout>
            <div className={styles.container}>
                <div className={styles.section}>
                    <div className={styles.sectionInner}>
                        {surveyCompleted &&
                            <div className={styles.coupon}>
                                <div className={typo.title}>
                                    Il tuo codice sconto
                                </div>
                                <div>
                                    Per ringraziarti della tua partecipazione, ecco un codice sconto di 50€ che potrai utilizzare per l'acquisto delle nostre masterclass. La piattaforma online della nostra Business School Velv sarà lanciata a Gennaio!
                                </div>
                                <img src={CouponIllustration} className={styles.couponIllustration} alt="coupon-illustration" />
                                <div className={styles.couponBox}>
                                    {copied ? 'COPIATO!' : 'VELV50'}
                                    <div className={styles.copy} onClick={copyInClipboard}>
                                        {copied ?
                                            <CheckIcon className={styles.copyIcon} /> :
                                            <CopyIcon className={styles.copyIcon} />}
                                    </div>
                                </div>
                                <Button
                                    onClick={() => {
                                        window.open("https://shop.startingfinance.com", "_blank")
                                    }}
                                    style={{ padding: '1rem 2rem', marginTop: '2rem' }}
                                >
                                    VAI ALLO SHOP
                                </Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </HeaderFooterLayout>
    )

}

export default Coupon