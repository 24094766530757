import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { ReactComponent as NotificationFillIcon } from "../assets/images/icons/ic-notification-active.svg"
import { ReactComponent as NotificationIcon } from "../assets/images/icons/ic-notification.svg"
import MainContext from "../common/MainContext"
import styles from "./MessagesButton.module.css"
import { ReactComponent as ChatIcon } from "../assets/images/icons/ic-chat.svg";

const NotificationsDropdown = () => {

    const context = useContext(MainContext)
    const navigate = useNavigate()

    const handleClick = async () => {
        navigate("/messages")
    }


    return (
        <>
            <div id="messages-button" className={styles.container} onClick={handleClick}>

                {context.unreadMessages === 0  &&
                    <ChatIcon className={styles.notificationIcon} />
                }
                {context.unreadMessages > 0 &&
                    <>
                        <ChatIcon className={styles.notificationIcon} />
                        <div className={styles.badge}>
                            {context.unreadMessages < 10 ? context.unreadMessages : '9+'}
                        </div>
                    </>
                }
            </div>
        </>
    )
}

export default NotificationsDropdown