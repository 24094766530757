import { useCallback, useEffect, useMemo, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { ReactComponent as ClockIcon } from "../../assets/images/icons/ic-clock.svg"
import { ReactComponent as ThumbnailExcel } from "../../assets/images/icons/ic-thumbnail-excel.svg"
import { ReactComponent as ThumbnailGeneric } from "../../assets/images/icons/ic-thumbnail-generic.svg"
import { ReactComponent as ThumbnailPdf } from "../../assets/images/icons/ic-thumbnail-pdf.svg"
import { ReactComponent as ThumbnailPpt } from "../../assets/images/icons/ic-thumbnail-ppt.svg"
import { ReactComponent as ThumbnailText } from "../../assets/images/icons/ic-thumbnail-text.svg"
import { ReactComponent as ThumbnailVideo } from "../../assets/images/icons/ic-thumbnail-video.svg"
import { ReactComponent as EditIcon } from "../../assets/images/icons/ic-edit-simple.svg"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import { formatDateV2, formatFileSize, formatTimeV2, getLocalDate } from "../../utils"
import styles from "./ExerciseMaterialCard.module.css"
import Button from "../Button"
import api from "../../api"

const supportedImageExtensions = ["jpg", "jpeg", "png", "svg", "webp", "gif", "avif", "apng"]
const supportedVideoExtensions = ["mp4", "m4p", "mov", "flv", "mkv", "avi", "wmv", "mpg", "mpeg", "m2v"]

const mediaExtensions = ["jpg", "jpeg", "png", "svg", "webp", "gif", "avif", "apng", "mp4", "m4p", "mov", "flv", "mkv", "avi", "wmv", "mpg", "mpeg", "m2v"]
const pptExtensions = ["ppt", "pptx"]
const excelExtensions = ["xlsx"]
const textExtensions = ["docx"]

const ExerciseMaterialCard = ({ exerciseId, material, onSubmit = () => { }, expiresAt }) => {
    const { width } = useWindowDimensions()
    const { t } = useTranslation()

    const [type, setType] = useState(null)
    const [file, setFile] = useState(null)
    const ref = useRef(null)

    const Thumbnail = useMemo(() => {
        if (material) {
            const fileExtension = material.name.split(".")[1].toLowerCase()
            if (mediaExtensions.includes(fileExtension)) {
                return ThumbnailVideo
            }
            if (pptExtensions.includes(fileExtension)) {
                return ThumbnailPpt
            }
            if (pptExtensions.includes(fileExtension)) {
                return ThumbnailPpt
            }
            if (excelExtensions.includes(fileExtension)) {
                return ThumbnailExcel
            }
            if (textExtensions.includes(fileExtension)) {
                return ThumbnailText
            }
            if (fileExtension === "pdf") {
                return ThumbnailPdf
            }
        }
        return ThumbnailGeneric
    }, [material])

    useEffect(() => {
        if (material) {
            const fileExtension = material.url.split(".").slice(-1)[0].toLowerCase()
            if (supportedImageExtensions.includes(fileExtension)) {
                setType("image")
            } else if (supportedVideoExtensions.includes(fileExtension)) {
                setType("video")
            } else if (fileExtension === "pdf") {
                setType("pdf")
            } else {
                setType(null)
            }
        }
    }, [material])

    useEffect(() => {
        if (file) {
            onEdit()
        }
    }, [file])

    const onEdit = useCallback(async () => {
        try {
            const formData = new FormData()
            formData.append("file", file)
            formData.append("id", material.id)

            await api.put(`/exercises/${exerciseId}`, formData, { headers: { "Content-Type": "multipart/form-data" } })
            setFile(null)
            onSubmit()
        } catch (e) {
            console.error(e)
        }
    }, [material, exerciseId, file])


    const openMaterial = useCallback(() => {
        const newWindow = window.open("", "_blank")
        let content = ""
        switch (type) {
            case "image":
                content = `<img src="${material.url}" alt=""/>`
                break
            case "video":
                content = `
                <video width="320" height="240" controls>
                    <source src="${material.url}">
                </video>`
                break
            case "pdf":
                content = `<embed src="${material.url}" width="500" height="375" type="application/pdf">`
                break
            default: break
        }

        newWindow.document.write(`
            <html>
                <head>
                </head>
                <body>
                ${content}
                </body>
            </html>
        `);



    }, [material, type])

    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <Thumbnail className={styles.thumbnail} />
                <div className={styles.material}>
                    <div className={styles.name}>{material.name}</div>
                    <div className={styles.size}>{formatFileSize(material.size)}</div>
                </div>
            </div>
            <div className={styles.action}>
                {
                    material.created_at &&
                    <div className={styles.time}>
                        {formatTimeV2(material.created_at)}, {formatDateV2(material.created_at)} <ClockIcon className={styles.icon} />
                    </div>
                }
                { getLocalDate(expiresAt) > new Date() &&
                <Button
                    accentColor={"var(--sf-orange)"}
                    onClick={() => {
                        if (ref?.current) {
                            setFile(null)
                            ref.current.value = null
                            ref.current.click()
                        }
                    }}
                    style={{ padding: ".6rem 1.5rem" }}
                >
                    <EditIcon style={{ width: "12px", height: "12px" }} />
                    {t("change").toUpperCase()}
                </Button>
                }
                <input ref={ref} type="file" id="file" name="file" accept="*" hidden onChange={(e) => {
                    if (e.target.files.length > 0) {
                        setFile(e.target.files[0])
                    } else {
                        setFile(null)
                    }
                }} />
            </div>
        </div>

    )
}

export default ExerciseMaterialCard
