import { HelmetProvider } from "react-helmet-async"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import styles from "./Welcome.module.css"
import { Helmet } from "react-helmet"
import { ReactComponent as WelcomeImage } from "../assets/images/illustrations/3D/welcome.svg"
import Checkbox from '../components/Checkbox';
import { useContext, useEffect, useState } from "react"
import Button from "../components/Button"
import { useNavigate } from "react-router-dom"
import MainContext from "../common/MainContext"
import Survey from "../pages/Survey"

const Welcome = () => {

    const context = useContext(MainContext)
    const navigate = useNavigate()
    const [checkbox, setCheckbox] = useState(false)
    const [showSurvey, setShowSurvey] = useState(false)

    useEffect(() => {
        localStorage.setItem("privacy", checkbox)
    }, [checkbox])

    useEffect(() => {
        // if (!context?.user?.isAnonymous) {
        //     navigate("/")
        // }
        if(context?.user && !context?.user?.mandatory_survey){
            setShowSurvey(true)
        }
    }, [context.user])

    return (
        <>
            {context.user && !showSurvey &&
                <HeaderFooterLayout hideFooter>
                    <HelmetProvider>
                        <Helmet>
                            <title>Welcome</title>
                        </Helmet>
                    </HelmetProvider>
                    <div className={styles.container}>
                        <div className={styles.section}>
                            <div className={styles.sectionInner}>
                                <div className={styles.title}>
                                    Benvenuto su Velv
                                </div>
                                <div className={styles.message}>
                                    Ciao, stiamo facendo <b>questo sondaggio</b> per dar voce alla <b>nostra community</b> e raccontare all'<b>Italia</b> come i giovani vivono il <b>mondo del lavoro</b>!
                                    <br />
                                    <br />
                                    Ti prenderemo solo <b>3 minuti</b>. Il <b>tuo contributo</b> sarà utilissimo per far emergere le cose positive e negative delle <b>aziende italiane</b>.
                                </div>
                                <WelcomeImage />
                                <div className={styles.privacyContainer}>
                                    <Checkbox onChange={v => setCheckbox(v)} value={checkbox} />
                                    <span>Dichiaro di aver letto e di accettare la <a className={styles.anchor} href="https://shop.startingfinance.com/pages/privacy-policy" target="_blank" rel="noreferrer">privacy policy</a></span>
                                </div>
                                <Button
                                    onClick={() => {
                                        navigate('/survey')
                                        setShowSurvey(true)
                                    }}
                                    disabled={checkbox === false}
                                    style={{ padding: "1rem 2rem", fontSize: "1.25rem", marginTop: "2rem" }}
                                >
                                    COMINCIA
                                </Button>
                            </div>
                        </div>
                    </div>
                </HeaderFooterLayout>
            }
            {context.user && showSurvey && <Survey />}
        </>
    )

}

export default Welcome