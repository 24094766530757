import styles from "./LiveBadge.module.css"

const LiveBadge = ({style, label}) => {

    return (
        <div className={styles.liveContainer} style={style}>
            <div className={styles.onair}></div>
            {label ? label : 'LIVE'}
        </div>
    )
}

export default LiveBadge