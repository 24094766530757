import { useContext, useEffect, useState } from "react"
import MainContext from "../common/MainContext"
import Landing from "./Landing"
import Dashboard from "./Dashboard"
import Login from "./Login"
import Coupon from "./Coupon"

const Home = () => {

    const [showCoupon, setShowCoupon] = useState(null)
    const context = useContext(MainContext)
    
    useEffect(() => {
        if(!context.hasSubscription){
            setShowCoupon(true)
        }
    }, [context])

    return (
        <>
            {context.user.isAnonymous &&
                <Login />
            }
            {!context.user.isAnonymous &&
                <>
                    {context.hasSubscription &&
                        <Dashboard />
                    }
                    {!context.hasSubscription && showCoupon &&
                        <Coupon />
                    }
                </>
            }
        </>
    )

}

export default Home