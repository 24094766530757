import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import { auth } from "../../firebase"
import Footer from "./Footer"
import Header from "./Header"
import styles from "./HeaderFooterLayout.module.css"
import TopNav from "./TopNav"

const HeaderFooterLayout = ({ children, hideFooter = false, hideHeader = false, style, ...props }) => {

    const [showHeader, setShowHeader] = useState(false)

    const [authUser, setAuthUser] = useState(undefined)

    useEffect(() => {
        auth.authStateReady().then(() => {
            setAuthUser(auth.currentUser)
        })
    }, [])

    useEffect(() => {
        if (authUser && !authUser.isAnonymous) {
            setShowHeader(true)
        }
    }, [authUser])

    return (
        <motion.div className={styles.container} style={style} {...props}>
            {/* {showHeader && !hideHeader &&
                <Header></Header>
            }
            {authUser?.isAnonymous &&
                <TopNav></TopNav>
            } */}
            
            <TopNav />
            <div className={styles.content}>
                {children}
            </div>
            {!hideFooter &&
                <Footer></Footer>
            }
        </motion.div>
    )
}

export default HeaderFooterLayout