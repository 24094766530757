import { Splide, SplideSlide } from "@splidejs/react-splide"
import confetti from 'canvas-confetti'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from "react-router-dom"
import TextareaAutosize from 'react-textarea-autosize'
import api from "../api"
import municipalitiesData from "../assets/comuni.json"
import { ReactComponent as WorkIcon } from "../assets/images/icons/ic-bag.svg"
import { ReactComponent as LogoutIcon } from "../assets/images/icons/ic-logout.svg"
import { ReactComponent as SchoolIcon } from "../assets/images/icons/ic-school.svg"
import { ReactComponent as ArrowIcon } from "../assets/images/icons/ic-arrow.svg"
import { ReactComponent as BullIllustration } from "../assets/images/illustrations/il-survey-completed.svg"
import { SurveyType } from "../common/constants.js"
import { Each } from "../common/Each.js"
import MainContext from "../common/MainContext.js"
import Progress from "../components/animated/Progress.js"
import StepHighlight from "../components/animated/StepHighlight.js"
import Button from "../components/Button.js"
import Card from "../components/cards/Card.js"
import DropdownSelection from "../components/DropdownSelection.js"
import HeaderFooterLayout from "../components/layouts/HeaderFooterLayout"
import TextInput from "../components/TextInput.js"
import { auth } from "../firebase"
import { findMostFrequent, range } from "../utils.js"
import styles from "./Survey.module.css"
import useWindowDimensions from "../common/hooks/useWindowDimensions.js"
import ReactMarkdown from 'react-markdown';

let delayTimeout = null
let hideTimeout = null

const Survey = () => {
  const context = useContext(MainContext)
  const location = useLocation()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const navigate = useNavigate()
  const [survey, setSurvey] = useState(null)
  const [groups, setGroups] = useState([])
  const [userAnswers, setUserAnswers] = useState([])
  const [hideConfetti, setHideConfetti] = useState(false)

  // inputs
  const [name, setName] = useState(context.user?.name ?? "")
  const [surname, setSurname] = useState(context.user?.surname ?? "")
  const [age, setAge] = useState(context.user?.age ? `${context.user?.age}` : "")
  const [city, setCity] = useState(null)
  const [defaultCity, setDefaultCity] = useState(null)
  const [gender, setGender] = useState(null)
  const [employment, setEmployment] = useState(null)

  // navigation
  const [index, setIndex] = useState(0)
  const [groupIndex, setGroupIndex] = useState(0)
  const [page, setPage] = useState(0)
  const [lastId, setLastId] = useState(null)
  const [end, setEnd] = useState(false)
  const [canContinue, setCanContinue] = useState(false)

  const splideGroupRef = useRef(null)
  const splideRefs = useRef([])
  const confettiRef = useRef(null)

  const municipalities = useMemo(() => {
    return municipalitiesData.map((m) => {
      return {
        id: m.codice,
        label: `${m.nome} (${m.sigla})`
      }
    })
  }, [])

  const genders = useMemo(() => {
    return [
      { label: t("survey.female"), value: "female" },
      { label: t("survey.male"), value: "male" },
      { label: t("survey.other"), value: "other" },
    ]
  }, [])

  const employments = useMemo(() => {
    return [
      { label: t("survey.employments.student"), value: "student", icon: SchoolIcon },
      { label: t("survey.employments.worker"), value: "worker", icon: WorkIcon },
      { label: t("survey.employments.studentWorker"), value: "studentWorker" },
      { label: t("survey.employments.neither"), value: "neither" },
    ]
  }, [])

  useEffect(() => {
    if (!survey) {
      return
    }

    const { content } = survey
    const groups = {}
    for (const s of content) {
      const { group } = s
      if (!groups[group]) {
        groups[group] = []
      }
      groups[group].push(s)
    }
    const g = Object.values(groups)
    setGroups(g)
  }, [survey])

  useEffect(() => {
    if (context.user && context.user.mandatory_survey) {
      const privacy = localStorage.getItem("privacy")
      if (!privacy || privacy === "false") {
        navigate("/welcome")
        return;
      }
    }
    if (context?.user && !context.user.isAnonymous) {

      setName(context.user.name)
      setSurname(context.user.surname)
      setAge(context.user.age ?? null)
      setGender(context.user.gender ?? null)
      setEmployment(context.user.employment ?? null)

      if (context.user.city) {
        const city = municipalities.find(m => m.label === context.user.city)
        if (city?.id) {
          setDefaultCity(city.id)
        }
      }
    }
  }, [context])

  useEffect(() => {

    const getSurvey = async () => {
      try {
        const survey = await api.get("/survey")
        setSurvey(survey)

        localStorage.setItem('surveyId', survey.id)
        localStorage.setItem('surveyContent', JSON.stringify(survey.content))

        if (survey.user_survey?.content) {
          setUserAnswers(survey.user_survey?.content)
        }
      } catch (e) {
        console.error(e)
      }
    }

    getSurvey()

    return () => {
      if (delayTimeout) {
        clearTimeout(delayTimeout)
        delayTimeout = null
      }

      if (hideTimeout) {
        clearTimeout(hideTimeout)
        hideTimeout = null
      }
    }
  }, [])

  useEffect(() => {
    if (splideGroupRef && splideGroupRef.current) {
      splideGroupRef.current.splide.go(page)
    }
  }, [page])

  useEffect(() => {
    if (splideRefs && splideRefs.current.length > 0) {
      splideRefs.current[groupIndex].splide.go(index)
    }
  }, [index, groupIndex])

  useEffect(() => {
    if (!end) {
      return
    }

    var onboarding = { ...context.onboarding }
    if (context.onboarding) {
      for (let step of onboarding.steps) {
        if (step.type === 'survey') {
          step.actions[0].completed = true
          step.completed = true
        }
      }

      context.setOnboarding({ ...onboarding })
    }

    if (splideGroupRef && splideGroupRef.current) {
      splideGroupRef.current.splide.go(groups.length + 1)
    }

    const myCanvas = document.createElement('canvas');
    if (confettiRef && confettiRef.current) {
      confettiRef.current.appendChild(myCanvas);
    }

    const myConfetti = confetti.create(myCanvas, {
      resize: true,
      useWorker: true
    });
    const count = 200;
    const defaults = {
      origin: { y: 0.38, decay: 0.2 }
    };

    function fire(particleRatio, opts) {
      myConfetti(Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio)
      }));
    }

    delayTimeout = setTimeout(() => {
      fire(0.25, {
        spread: 70,
        startVelocity: 55,
        decay: 0.85

      });
      fire(0.2, {
        spread: 200,
        decay: 0.8

      });
      fire(0.35, {
        spread: 100,
        decay: 0.7,
        scalar: 0.9
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.8,
        scalar: 1.2
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
        decay: 0.8
      });

      delayTimeout = null
    }, 500)

    hideTimeout = setTimeout(() => {
      setHideConfetti(true)
      hideTimeout = null
    }, 4000)
  }, [end])

  useEffect(() => {
    if (page === 0) {

      setCanContinue(
        !!name &&
        !!surname &&
        !isNaN(age) &&
        !!city &&
        !!gender
        // && !!employment
      )
    }
  }, [age, city, gender, employment, page, name, surname])

  useEffect(() => {
    const cachedUserAnswers = localStorage.getItem("surveyUserAnswers")
    if (cachedUserAnswers) {
      setUserAnswers(JSON.parse(cachedUserAnswers))
    }
    const cachedUser = localStorage.getItem("userData")
    if (cachedUser) {

      const data = JSON.parse(cachedUser)
      const city = municipalities.find(m => m.label === data?.city)
      setName(data?.name ?? "")
      setSurname(data?.surname ?? "")
      setAge(data?.age ?? "")
      setCity(city)
      setDefaultCity(city?.id)
      setGender(genders.find(g => g.value === data?.gender)?.value ?? null)
      setEmployment(employments.find(e => e.value === data?.employment)?.value ?? null)
    }
  }, [])

  useEffect(() => {
    if (context.user.isAnonymous) {
      localStorage.setItem('surveyUserAnswers', JSON.stringify(userAnswers));
    }
  }, [userAnswers])

  // useEffect(() => {
  //   localStorage.setItem("userData", JSON.stringify({
  //     name, surname, age, gender, city: city?.label
  //   }))
  // }, [name, surname, age, gender, city])

  const endSurvey = useCallback(async () => {
    try {
      await api.post(`/survey/${survey.id}`, {
        content: { content: userAnswers }
      })
    } catch (e) {
      console.error(e)
    }
  }, [userAnswers, survey])

  const setCanContinueWithCheck = useCallback((value) => {
    if (
      page > 0 &&
      page <= groups.length &&
      groups.length > groupIndex &&
      groups[groupIndex].length > index
    ) {
      const { required } = groups[groupIndex][index]
      if (required === false) {
        setCanContinue(true)
      } else {
        setCanContinue(value)
      }
    }
  }, [page, groups, groupIndex, index])

  useEffect(() => {
    if (
      page > 0 &&
      page <= groups.length &&
      groups.length > groupIndex &&
      groups[groupIndex].length > index
    ) {
      const { id, type, required } = groups[groupIndex][index]
      const ua = userAnswers.find(ua => ua.id === id)
      if (ua) {
        setCanContinueWithCheck(true)
      } else if (required === false) {
        setCanContinue(true)

        switch (type) {
          case SurveyType.SingleChoice: onSingleChoiceClick(null); break
          case SurveyType.MultipleChoice: onMultipleChoiceClick(null); break
          case SurveyType.Open: onOpenChange(null); break
          case SurveyType.Numeric: onNumericClick(null, null); break
        }
      }
    }
  }, [page, groups, groupIndex, index, userAnswers, setCanContinueWithCheck])


  const onSingleChoiceClick = useCallback((optionIndex, openValue = null) => {
    if (optionIndex === null) {
      const newAnswer = {
        id: groups[groupIndex][index].id,
        type: SurveyType.SingleChoice,
        text: groups[groupIndex][index].text,
        answer: null,
        answerId: null,
        previous: lastId,
        brief: groups[groupIndex][index].brief ?? "",
        next: groups[groupIndex][index].next,
      }
      userAnswers.push(newAnswer)
      setUserAnswers([...userAnswers])
      return
    }

    const id = groups[groupIndex][index].id
    const option = groups[groupIndex][index].options[optionIndex]
    const { type: optionType } = option
    const isOpenOption = optionType === "open"

    const newAnswer = {
      id,
      type: SurveyType.SingleChoice,
      text: groups[groupIndex][index].text,
      answer: isOpenOption ? { ...groups[groupIndex][index].options[optionIndex], answerValue: openValue } :
        groups[groupIndex][index].options[optionIndex],
      answerId: optionIndex,
      previous: lastId,
      brief: groups[groupIndex][index].brief ?? ""
    }
    const userAnswersIndex = userAnswers.findIndex(ua => ua.id === id)
    setUserAnswers(userAnswers => {
      if (userAnswersIndex === -1) { // nessuna risposta data
        if (!isOpenOption || (isOpenOption && openValue)) {
          userAnswers.push(newAnswer)
          setCanContinueWithCheck(true)
        } else if (isOpenOption && !openValue) {
          setCanContinueWithCheck(false)
        }
      } else { // esiste già una risposta
        if (userAnswers[userAnswersIndex].answerId === optionIndex) { // click sulla stessa risposta
          if (!isOpenOption) {
            userAnswers = userAnswers.slice(0, userAnswersIndex)
            setCanContinueWithCheck(false)
          } else {
            if (openValue) { // aggiorno il testo della risposta
              userAnswers[userAnswersIndex].answer.answerValue = openValue
              setCanContinueWithCheck(true)
            } else { // elimino la risposta perchè vuota
              userAnswers = userAnswers.slice(0, userAnswersIndex)
              setCanContinueWithCheck(false)
            }
          }
        } else { // click su un'altra risposta
          if (!isOpenOption || (isOpenOption && openValue)) {
            userAnswers[userAnswersIndex] = newAnswer
            setCanContinueWithCheck(true)
          } else if (isOpenOption && !openValue) {
            setCanContinueWithCheck(false)
            userAnswers = userAnswers.slice(0, userAnswersIndex)
          }
        }
      }
      return [...userAnswers]
    })

  }, [userAnswers, groups, groupIndex, index, lastId, setCanContinueWithCheck])

  const onMultipleChoiceClick = useCallback((optionIndex, openValue = null) => {
    if (optionIndex === null) {
      const newAnswer = {
        id: groups[groupIndex][index].id,
        type: SurveyType.MultipleChoice,
        text: groups[groupIndex][index].text,
        answers: [],
        brief: groups[groupIndex][index].brief ?? "",
        previous: lastId,
        next: groups[groupIndex][index].next,
      }
      userAnswers.push(newAnswer)
      setUserAnswers([...userAnswers])
      return
    }

    const id = groups[groupIndex][index].id
    const option = groups[groupIndex][index].options[optionIndex]
    const { type: optionType } = option
    const isOpenOption = optionType === "open"

    const newValue = { id: optionIndex, ...groups[groupIndex][index].options[optionIndex] }
    if (openValue) {
      newValue.answerValue = openValue
    }

    const newAnswer = {
      id,
      type: SurveyType.MultipleChoice,
      text: groups[groupIndex][index].text,
      answers: [newValue],
      brief: groups[groupIndex][index].brief ?? "",
      previous: lastId
    }
    const userAnswersIndex = userAnswers.findIndex(ua => ua.id === id)
    setUserAnswers(userAnswers => {
      if (userAnswersIndex === -1) {
        if (!isOpenOption || (isOpenOption && openValue)) {
          userAnswers.push(newAnswer)
          setCanContinueWithCheck(true)
        }
      } else { // click su una risposta già selezionata
        if (userAnswers[userAnswersIndex].answers.map(a => a.id).includes(optionIndex)) {
          if (userAnswers[userAnswersIndex].answers.length > 1 && (openValue === null || openValue === "")) {
            // remove ids from list but keep other answer selected
            userAnswers[userAnswersIndex].answers.splice(
              userAnswers[userAnswersIndex].answers.findIndex(a => a.id === optionIndex),
              1
            )
            setCanContinueWithCheck(true)
          } else if (openValue === null) {
            // remove successive answers
            userAnswers = userAnswers.slice(0, userAnswersIndex)
            setCanContinueWithCheck(false)
          } else { // setta openValue alla risposta aperta già esistente
            const idx = userAnswers[userAnswersIndex].answers.findIndex(a => a.id === optionIndex)
            if (openValue === "") {
              userAnswers[userAnswersIndex].answers.splice(idx, 1) // esiste come unica risposta ma il testo è vuoto
              setCanContinueWithCheck(false)
            } else {
              userAnswers[userAnswersIndex].answers[idx].answerValue = openValue
            }
          }
        } else {
          if (!isOpenOption || (isOpenOption && openValue)) {
            userAnswers[userAnswersIndex].answers.push(newValue)
            setCanContinueWithCheck(true)
          }
        }
      }
      return [...userAnswers]
    })

  }, [userAnswers, groups, groupIndex, index, lastId, setCanContinueWithCheck])

  const onNumericClick = useCallback((value, optionIndex) => {
    if (optionIndex === null) {
      const newAnswer = {
        id: groups[groupIndex][index].id,
        type: SurveyType.Numeric,
        text: groups[groupIndex][index].text,
        next: groups[groupIndex][index].next,
        brief: groups[groupIndex][index].brief ?? "",
        answer: null,
        answerId: null,
        previous: lastId
      }
      userAnswers.push(newAnswer)
      setUserAnswers([...userAnswers])
      return
    }

    const id = groups[groupIndex][index].id
    const newAnswer = {
      id,
      type: SurveyType.Numeric,
      text: groups[groupIndex][index].text,
      next: groups[groupIndex][index].next,
      brief: groups[groupIndex][index].brief ?? "",
      answer: value,
      answerId: optionIndex,
      previous: lastId
    }

    const userAnswersIndex = userAnswers.findIndex(ua => ua.id === id)
    if (userAnswersIndex === -1) {
      userAnswers.push(newAnswer)
      setUserAnswers([...userAnswers])
      setCanContinueWithCheck(true)
    } else {
      if (userAnswers[userAnswersIndex].answerId === optionIndex) {
        setUserAnswers(userAnswers.slice(0, userAnswersIndex))
        setCanContinueWithCheck(false)
      } else {
        userAnswers[userAnswersIndex] = newAnswer
        setUserAnswers([...userAnswers])
        setCanContinueWithCheck(true)
      }

    }

  }, [userAnswers, groups, groupIndex, index, lastId, setCanContinueWithCheck])

  const goBack = useCallback(() => {
    if (page === 1 && index === 0) {
      setPage(0)
      setUserAnswers([])
      return
    }

    if (lastId) {
      for (let i = 0; i < groups.length; i++) {
        for (let j = 0; j < groups[i].length; j++) {
          if (groups[i][j].id === lastId) {
            setPage(i + 1)
            setGroupIndex(i)
            setIndex(j)
            setCanContinueWithCheck(true)
            const answerIdx = userAnswers.findIndex(ua => ua.id === groups[i][j].id)
            if (answerIdx !== -1) {
              setLastId(userAnswers[answerIdx].previous)
            }
            setUserAnswers(userAnswers.slice(0, answerIdx + 1))
            return
          }
        }
      }
    }
  }, [lastId, groups, index, userAnswers, page, setCanContinueWithCheck])

  const goForward = useCallback(async () => {
    if (page === 0) {
      setPage(1)
      setCanContinue(false)

      if (!context.user.isAnonymous) {
        await save()
      }
      return
    }

    const id = groups[groupIndex][index].id
    const currentAnswer = userAnswers.find(ua => ua.id === id)
    const { type } = currentAnswer
    setCanContinue(false)

    let targetId = null
    switch (type) {
      case SurveyType.SingleChoice:
        targetId = currentAnswer.answer?.next ?? currentAnswer.next // se facoltativa deve contenere un next di primo livello
        break
      case SurveyType.MultipleChoice:
        targetId = currentAnswer.answers.length > 0 ? parseInt(findMostFrequent(currentAnswer.answers.map(a => a.next))) : currentAnswer.next // se facoltativa deve contenere un next di primo livello
        break
      case SurveyType.Open:
        targetId = currentAnswer.next
        break
      case SurveyType.Numeric:
        targetId = currentAnswer.next
        break
      default:
        console.error("Unsupported Survey type")
        break
    }
    for (let i = 0; i < groups.length; i++) {
      for (let j = 0; j < groups[i].length; j++) {
        if (groups[i][j].id === targetId) {
          setPage(i + 1)
          setGroupIndex(i)
          setIndex(j)
          setLastId(id)
          const answer = userAnswers.find(ua => ua.id === groups[i][j].id)
          if (answer) {
            setCanContinueWithCheck(true)
          }
          return
        }
      }
    }

    setPage(groups.length + 1)
    setEnd(true)
    if (!context.user.isAnonymous) {
      endSurvey()
    }
  }, [groups, userAnswers, groupIndex, index, page, endSurvey, age, name, surname, employment, city, gender, context, setCanContinueWithCheck])

  const onOpenChange = useCallback((e) => {
    if (e === null) {
      const newAnswer = {
        id: groups[groupIndex][index].id,
        type: SurveyType.Open,
        text: groups[groupIndex][index].text,
        brief: groups[groupIndex][index].brief ?? "",
        value: "",
        previous: lastId,
        next: groups[groupIndex][index].next
      }
      userAnswers.push(newAnswer)
      setUserAnswers([...userAnswers])
      return
    }
    const { value } = e.target
    setCanContinueWithCheck(!!value)
    const id = groups[groupIndex][index].id
    const newAnswer = {
      id,
      type: SurveyType.Open,
      text: groups[groupIndex][index].text,
      brief: groups[groupIndex][index].brief ?? "",
      value,
      previous: lastId,
      next: groups[groupIndex][index].next
    }
    const userAnswersIndex = userAnswers.findIndex(ua => ua.id === id)

    if (userAnswersIndex !== -1) {
      userAnswers.splice(userAnswersIndex, 1)
    }
    userAnswers.push(newAnswer)
    setUserAnswers([...userAnswers])
  }, [userAnswers, groups, lastId, groupIndex, index, setCanContinueWithCheck])


  const save = async (end = false) => {
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("surname", surname);
      formData.append("age", age);
      formData.append("gender", gender);
      formData.append("city", city.label);
      if (employment) {
        formData.append("employment", employment);
      }
      const user = await api.put("/user", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      if (user) {
        context.setUser(user)
      }
    } catch (e) {
      console.error(e)
    }

    if (end) {
      await endSurvey()
      if (location.pathname === '/') {
        window.location.reload(false)
      }
      else {
        navigate("/")
      }
    }
  }

  return (
    <HeaderFooterLayout hideFooter>
      <HelmetProvider>
        <Helmet>
          <title>Questionario</title>
        </Helmet>
      </HelmetProvider>
      <div className={styles.container}>
        <>
          {context?.user?.mandatory_survey === false && location.pathname !== "/survey" &&
            <div className={styles.registration} style={{ padding: '2rem' }}>
              <div className={styles.question}>
                {t("survey.completeRegistration")}
              </div>
              <div className={styles.registrationInputs}>
                <div className={styles.registrationSection}>
                  <TextInput
                    type={"text"}
                    value={name}
                    placeholder={t("survey.name")}
                    onKeyUp={(value) => {
                      setName(value)
                    }} style={{ minHeight: "60px", minWidth: "100%" }} />
                  <TextInput
                    type={"text"}
                    value={surname}
                    placeholder={t("survey.surname")}
                    onKeyUp={(value) => {
                      setSurname(value)
                    }} style={{ minHeight: "60px", minWidth: "100%" }} />
                  <TextInput
                    type={"number"}
                    value={age}
                    placeholder={t("survey.age")}
                    onKeyUp={(value) => {
                      setAge(value)
                    }} style={{ minHeight: "60px", minWidth: "100%" }} />
                  <DropdownSelection
                    search
                    required
                    placeholder={t("survey.municipality")}
                    options={municipalities}
                    style={{ width: "100%" }}
                    defaultOption={defaultCity}
                    onSelect={(id) => {
                      if (id) {
                        const city = municipalities.find(m => m.id === id)
                        if (city) {
                          setCity(city)
                        }
                      } else {
                        setCity(null)
                      }
                    }} />
                </div>
                <div className={styles.registrationSection}>
                  <div className={styles.registrationTitle}>{t("survey.gender")}</div>
                  <div className={styles.genders}>
                    <Each
                      of={genders}
                      render={(g) => (
                        <div
                          className={gender === g.value ? `${styles.box} ${styles.selected}` : styles.box}
                          onClick={() => {
                            if (g.value === gender) {
                              setGender(null)
                            } else {
                              setGender(g.value)
                            }
                          }}
                        >
                          {g.label}
                        </div>
                      )}
                    />
                  </div>
                </div>
                {/* <div className={styles.registrationSection}>
                  <div className={styles.registrationTitle}>{t("survey.employment")}</div>
                  <div className={styles.employments}>
                    <Each
                      of={employments}
                      render={(e) => (
                        <div
                          className={employment === e.value ? `${styles.box} ${styles.selected}` : styles.box}
                          onClick={() => {
                            if (e.value === employment) {
                              setEmployment(null)
                            } else {
                              setEmployment(e.value)
                            }
                          }}
                        >
                          {e.icon && <e.icon />}
                          {e.label}
                        </div>
                      )}
                    />
                  </div>
                </div> */}
              </div>
              <Button style={{ padding: '.8rem 2rem', marginTop: '2rem' }} disabled={!canContinue} onClick={() => { save(true) }}>SALVA</Button>
            </div>
          }
          {(context?.user?.mandatory_survey || location.pathname === '/survey') && groups && groups.length > 0 &&
            <div className={styles.survey}>
              <div className={styles.top}>
                <div className={styles.groupProgress}>
                  <Progress nSteps={1 + groups.length} nCompleted={page} />
                </div>
                <div className={styles.slideContainer}>
                  <Splide aria-label="..." ref={splideGroupRef} options={{
                    rewind: false,
                    drag: false,
                    autoplay: false,
                    pagination: false,
                    height: "100%",
                  }}>
                    <SplideSlide>
                      {
                        page === 0 &&
                        <div className={styles.registration}>
                          <div className={styles.question}>
                            {t("survey.completeRegistration")}
                          </div>
                          <div className={styles.registrationInputs}>
                            <div className={styles.registrationSection}>
                              <TextInput
                                type={"text"}
                                value={name}
                                placeholder={t("survey.name")}
                                onKeyUp={(value) => {
                                  setName(value)
                                  if (context.user.isAnonymous) {
                                    localStorage.setItem("userData", JSON.stringify({
                                      name: value, surname, age, gender, city: city?.label
                                    }))
                                  }
                                }} style={{ minHeight: "60px", minWidth: "100%" }} />
                              <TextInput
                                type={"text"}
                                value={surname}
                                placeholder={t("survey.surname")}
                                onKeyUp={(value) => {
                                  setSurname(value)
                                  if (context.user.isAnonymous) {

                                    localStorage.setItem("userData", JSON.stringify({
                                      name, surname: value, age, gender, city: city?.label
                                    }))
                                  }
                                }} style={{ minHeight: "60px", minWidth: "100%" }} />
                              <TextInput
                                type={"number"}
                                value={age}
                                placeholder={t("survey.age")}
                                onKeyUp={(value) => {
                                  setAge(value)
                                  if (context.user.isAnonymous) {

                                    localStorage.setItem("userData", JSON.stringify({
                                      name, surname, age: value, gender, city: city?.label
                                    }))
                                  }
                                }} style={{ minHeight: "60px", minWidth: "100%" }} />
                              <DropdownSelection
                                search
                                required
                                placeholder={t("survey.municipality")}
                                options={municipalities}
                                style={{ width: "100%" }}
                                defaultOption={defaultCity}
                                onSelect={(id) => {
                                  if (id) {
                                    const city = municipalities.find(m => m.id === id)
                                    if (city) {
                                      setCity(city)
                                      if (context.user.isAnonymous) {
                                        localStorage.setItem("userData", JSON.stringify({
                                          name, surname, age, gender, city: city?.label
                                        }))
                                      }
                                    }
                                  } else {
                                    setCity(null)
                                  }
                                }} />
                            </div>
                            <div className={styles.registrationSection}>
                              <div className={styles.registrationTitle}>{t("survey.gender")}</div>
                              <div className={styles.genders}>
                                <Each
                                  of={genders}
                                  render={(g) => (
                                    <div
                                      className={gender === g.value ? `${styles.box} ${styles.selected}` : styles.box}
                                      onClick={() => {
                                        if (g.value === gender) {
                                          setGender(null)
                                        } else {
                                          setGender(g.value)
                                          if (context.user.isAnonymous) {

                                            localStorage.setItem("userData", JSON.stringify({
                                              name, surname, age, gender: g.value, city: city?.label
                                            }))
                                          }
                                        }
                                      }}
                                    >
                                      {g.label}
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                            {/* <div className={styles.registrationSection}>
                              <div className={styles.registrationTitle}>{t("survey.employment")}</div>
                              <div className={styles.employments}>
                                <Each
                                  of={employments}
                                  render={(e) => (
                                    <div
                                      className={employment === e.value ? `${styles.box} ${styles.selected}` : styles.box}
                                      onClick={() => {
                                        if (e.value === employment) {
                                          setEmployment(null)
                                        } else {
                                          setEmployment(e.value)
                                        }
                                      }}
                                    >
                                      {e.icon && <e.icon />}
                                      {e.label}
                                    </div>
                                  )}
                                />
                              </div>
                            </div> */}
                          </div>
                        </div>
                      }
                    </SplideSlide>
                    <Each
                      of={groups}
                      render={(group, i) => (
                        <SplideSlide>
                          <div className={styles.groupContainer}>
                            <div className={styles.groupName}>
                              {group[0].group.toUpperCase()}
                            </div>
                            <div className={styles.innerSplideContainer}>
                              <Splide aria-label="..." ref={(element) => splideRefs.current.push(element)} options={{
                                rewind: false,
                                drag: false,
                                autoplay: false,
                                width: "100%",
                                pagination: false
                              }}>
                                <Each
                                  of={group}
                                  render={(surveyQuestion, j) => (
                                    <SplideSlide>
                                      {
                                        page === (i + 1) && index === j &&
                                        <div className={styles.questionContainer}>
                                          <div className={styles.question}>{surveyQuestion.text}</div>
                                          {
                                            surveyQuestion.type === SurveyType.SingleChoice &&
                                            <div className={styles.options}
                                              style={{
                                                flexDirection: surveyQuestion.options.length > 2 ? "column" : "row"
                                              }}
                                            >
                                              <Each
                                                of={surveyQuestion.options}
                                                render={(option, optionIdx) => {
                                                  const userAnswer = userAnswers.find(ua => ua.id === surveyQuestion.id)
                                                  let className = option.type === "open" ? styles.openOptionCard : styles.option
                                                  let textClassName = option.type === "open" ? styles.openOptionText : ""
                                                  if (userAnswer) {
                                                    if (userAnswer.answerId === optionIdx) {
                                                      className = `${className} ${styles.selected}`
                                                      textClassName = `${textClassName} ${styles.selected}`
                                                    } else {
                                                      className = `${className} ${styles.discarded}`
                                                      textClassName = `${textClassName} ${styles.discarded}`
                                                    }
                                                  }
                                                  return (
                                                    <>
                                                      {(!option.type || option.type === "text") &&
                                                        <div onClick={() => onSingleChoiceClick(optionIdx)}
                                                          className={className}>
                                                          <ReactMarkdown children={option.value} className={"inlineMarkdown"} />
                                                        </div>
                                                      }
                                                      {option.type && option.type === "open" &&
                                                        <div className={styles.openOption}
                                                          onClick={() => onSingleChoiceClick(optionIdx, userAnswers.find(ua => ua.id === surveyQuestion.id)?.answer?.answerValue ?? "")}>
                                                          <div className={textClassName}>
                                                            <ReactMarkdown children={option.value} className={"inlineMarkdown"} />
                                                          </div>
                                                          <div className={className}>
                                                            <div className={styles.textAreaContainer}>
                                                              <TextareaAutosize
                                                                value={userAnswers.find(ua => ua.id === surveyQuestion.id)?.answer?.answerValue ?? ""}
                                                                minRows={3} maxRows={5}
                                                                type="text"
                                                                className={`${styles.textArea} ${styles.small}`}
                                                                onChange={e => {
                                                                  const { value } = e.target
                                                                  onSingleChoiceClick(optionIdx, value)
                                                                }} />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      }
                                                    </>
                                                  )
                                                }}
                                              />
                                            </div>
                                          }

                                          {
                                            surveyQuestion.type === SurveyType.MultipleChoice &&
                                            <div className={styles.options}
                                              style={{
                                                flexDirection: surveyQuestion.options.length > 2 ? "column" : "row"
                                              }}
                                            >
                                              <Each
                                                of={surveyQuestion.options}
                                                render={(option, optionIdx) => {
                                                  const userQuestion = userAnswers.find(ua => ua.id === surveyQuestion.id)
                                                  const userAnswer = userQuestion?.answers.find(a => a.id === optionIdx)
                                                  let className = option.type === "open" ? styles.openOptionCard : styles.option
                                                  if (userAnswer) {
                                                    className = `${className} ${styles.selected}`
                                                  }
                                                  return (
                                                    <>
                                                      {(!option.type || option.type === "text") &&
                                                        <div
                                                          className={className}
                                                          onClick={() => onMultipleChoiceClick(optionIdx)}>
                                                          <ReactMarkdown children={option.value} className={"inlineMarkdown"} />
                                                        </div>
                                                      }

                                                      {
                                                        option.type && option.type === "open" &&
                                                        <div className={styles.openOption}
                                                          onClick={() => onMultipleChoiceClick(optionIdx, userAnswers.find(ua => ua.id === surveyQuestion.id)?.answers?.find(a => a.id === optionIdx)?.answerValue ?? "")}>
                                                          <div className={styles.openOptionText}>
                                                            <ReactMarkdown children={option.value} className={"inlineMarkdown"} />
                                                          </div>
                                                          <div className={className}>
                                                            <div className={styles.textAreaContainer}>
                                                              <TextareaAutosize
                                                                value={userAnswers.find(ua => ua.id === surveyQuestion.id)?.answers?.find(a => a.id === optionIdx)?.answerValue ?? ""}
                                                                minRows={3} maxRows={5}
                                                                type="text"
                                                                className={`${styles.textArea} ${styles.small}`}
                                                                onChange={e => {
                                                                  const { value } = e.target
                                                                  onMultipleChoiceClick(optionIdx, value)
                                                                }} />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      }
                                                    </>
                                                  )
                                                }}
                                              />
                                            </div>
                                          }

                                          {
                                            surveyQuestion.type === SurveyType.Numeric &&
                                            <div className={styles.numericOptions}>
                                              <Each
                                                of={range(surveyQuestion.min, surveyQuestion.max, surveyQuestion.step)}
                                                render={(option, optionIdx) => {
                                                  const userAnswer = userAnswers.find(ua => ua.id === surveyQuestion.id)
                                                  let className = styles.numericOption
                                                  if (userAnswer) {
                                                    if (userAnswer.answerId === optionIdx) {
                                                      className = `${styles.numericOption} ${styles.selected}`
                                                    } else {
                                                      className = `${styles.numericOption} ${styles.discarded}`
                                                    }
                                                  }
                                                  return (
                                                    <div
                                                      className={className}
                                                      onClick={() => onNumericClick(option, optionIdx)}>
                                                      {option}
                                                    </div>
                                                  )
                                                }}
                                              />
                                            </div>
                                          }

                                          {
                                            surveyQuestion.type === SurveyType.Open &&
                                            <div className={styles.options}>
                                              <div className={styles.openContainer}>
                                                <div className={styles.helperText}>
                                                  {t("typeHere")}
                                                </div>
                                                <Card hover style={{ padding: ".5rem 1rem", width: '100%' }} title={t("tests.description")}>
                                                  <div className={styles.textAreaContainer}>
                                                    <TextareaAutosize
                                                      value={userAnswers.find(ua => ua.id === surveyQuestion.id)?.value}
                                                      minRows={3} maxRows={15}
                                                      type="text"
                                                      className={styles.textArea}
                                                      onChange={onOpenChange} />
                                                  </div>
                                                </Card>
                                              </div>
                                            </div>
                                          }
                                        </div>
                                      }
                                    </SplideSlide>
                                  )}
                                />
                              </Splide>
                            </div>
                          </div>
                        </SplideSlide>
                      )}
                    />
                    <SplideSlide>
                      {
                        end &&
                        <div className={styles.groupContainer} style={{ height: "70dvh", overflow: "visible" }}>
                          <div className={styles.question} style={{ overflow: "visible" }}>
                            {t("survey.thanks")}
                          </div>
                          <div>
                            {location.pathname !== '/survey' ? t("survey.message") : ''}
                          </div>
                          <div className={styles.bull}>
                            {!hideConfetti && <div className={styles.confetti} ref={confettiRef} />}
                            <BullIllustration />
                          </div>
                          <Button
                            style={{ marginTop: "1rem" }}
                            accentColor={"var(--tertiary)"}
                            onClick={() => {
                              if (context.user.isAnonymous) {
                                context.setLoginDialog(true)
                              }
                              else {
                                if (location.pathname === '/') {
                                  window.location.reload(false)
                                }
                                else {
                                  navigate('/')
                                }
                              }
                            }}
                          >
                            {(location.pathname === '/survey' && !context.user.isAnonymous) ? t("explore").toUpperCase() : 'OTTIENI LO SCONTO'}
                            {/* {t("explore").toUpperCase()} */}
                          </Button>
                          <div className={styles.spacer} />
                        </div>
                      }
                    </SplideSlide>
                  </Splide>
                </div>
              </div>
              {
                end === false &&
                <div className={styles.pagination}>
                  {
                    page > 0 &&
                    <StepHighlight nSteps={groups[groupIndex].length} currentStep={
                      userAnswers.findIndex(ua => ua.id === groups[groupIndex][index].id) === -1 ? index : index + 1
                    } />
                  }
                  <div className={styles.pageButtons}
                    style={{
                      borderTop: page === 0 ? "1px solid rgba(var(--text-color-rgb), 12%)" : "none"
                    }}
                  >
                    {
                      page === 0 && index === 0 &&
                      <>
                        {location.pathname !== '/survey' &&
                          <Button
                            inverse
                            additionalClass={styles.actionButton}
                            onClick={async () => {
                              await auth.signOut()
                              localStorage.removeItem('userData')
                              localStorage.removeItem('surveyId')
                              localStorage.removeItem('surveyContent')
                              localStorage.removeItem('surveyUserAnswers')
                              context.setUser(null)
                              context.setCart(null)
                              window.location.reload(false)
                            }}
                            accentColor={"var(--secondary)"}
                          >
                            <LogoutIcon />
                            {t("exit").toUpperCase()}
                          </Button>
                        }
                        {location.pathname === '/survey' &&
                          <div style={{ width: '100%' }} />
                        }
                      </>

                    }
                    {
                      page > 0 &&
                      <Button
                        style={{ padding: width > 540 ? ".5rem 4rem" : "1rem 2rem" }}
                        inverse
                        disabled={page === 0 && index === 0}
                        onClick={goBack}
                      >
                        <ArrowIcon />
                        {width > 540 && t("back").toUpperCase()}
                      </Button>
                    }
                    <Button
                      // additionalClass={styles.actionButton}
                      style={{ padding: width > 540 ? ".5rem 4rem" : "1rem 2rem" }}
                      disabled={canContinue === false}
                      onClick={goForward}>
                      {width <= 540 && <ArrowIcon style={{ transform: "rotate(180deg)" }} />}
                      {width > 540 && t("continue").toUpperCase()}
                    </Button>
                  </div>
                </div>
              }
            </div>
          }
        </>
      </div>
    </HeaderFooterLayout >
  )
}


export default Survey
