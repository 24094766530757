import styles from "./LoginDialog.module.css"
import typo from "../../typography.module.css"
import Dialog from "./Dialog"
import TextInput from "../TextInput"
import Button from "../Button"
import { ReactComponent as AppleLogo } from '../../assets/images/apple.svg';
import { ReactComponent as GoogleLogo } from '../../assets/images/google.svg';
import { useTranslation } from "react-i18next"
import { useContext, useState } from "react"
import { GoogleAuthProvider, OAuthProvider, signInWithEmailAndPassword, signInWithPopup, createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import MainContext from "../../common/MainContext"
import api from "../../api"
import { useLocation, useNavigate } from "react-router-dom"
import { ButtonStatus } from "../../common/constants"
import { isEmptyNullUndefined } from "../../utils"

const LoginDialog = ({ open, onClose }) => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const context = useContext(MainContext)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('')
    const [error, setError] = useState(null)
    const [signUpMode, setSignUpMode] = useState(true)
    const [loading, setLoading] = useState(false)

    const googleProvider = new GoogleAuthProvider()
    googleProvider.setCustomParameters({
        // Localize the Apple authentication screen in Italian.
        locale: 'it'
    });

    const appleProvider = new OAuthProvider('apple.com')
    appleProvider.addScope('email');
    appleProvider.addScope('name');
    appleProvider.setCustomParameters({
        // Localize the Apple authentication screen in Italian.
        locale: 'it'
    });

    const googleSignIn = async () => {
        try {
            await signInWithPopup(auth, googleProvider)
            let user = await api.get("/user")
            if (user) {
                await updateFromLocalStorage(user)
            }
        }
        catch (error) {
            console.error("Error Google Sign In: ", error)
        }
    }

    const appleSignIn = async () => {
        try {
            await signInWithPopup(auth, appleProvider)
            let user = await api.get("/user")
            if (user) {
                await updateFromLocalStorage(user)
            }
        }
        catch (error) {
            console.error("Error Apple Sign In: ", error)
        }
    }

    const signIn = async () => {
        setLoading(true)
        try {
            await signInWithEmailAndPassword(auth, email, password)
            let user = await api.get("/user")
            if (user) {
                await updateFromLocalStorage(user)
            }
        }
        catch (error) {
            if (error.message.includes('invalid-credential')) {
                setError({ code: -1, message: t('errors.invalidCredentials') })
            }
            else {
                setError({ code: -1, message: error.message })
            }
        }
        setLoading(false)
    }

    const signUp = async () => {
        setLoading(true)
        try {
            if (!checkPasswords()) {
                return
            }
            await createUserWithEmailAndPassword(auth, email, password)
            let user = await api.get("/user")
            if (user) {
                await updateFromLocalStorage(user)
            }
        }
        catch (error) {
            if (error.message.includes('already-in-use')) {
                setError({ code: -1, message: "Questo utente è già registrato." })
            }
            else {
                setError({ code: -1, message: error.message })
            }
        }
        setLoading(false)
    }


    const onEmailChange = (value) => {
        setEmail(value)
        if (!validateEmail(value)) {
            setError({ code: -1, message: t('errors.invalidEmail') })
        }
        else {
            setError(null)
        }
    }

    const onPasswordChange = (value) => {
        setPassword(value)
    }

    const onPasswordRepeatChange = (value) => {
        setRepeatPassword(value)
        if (signUp) {
            setError(checkPasswords() ? null : { code: -1, message: t('errors.passwordNotEquals') })
        }
    }

    const checkPasswords = () => {
        return (password.length > 0 && repeatPassword.length > 0) ? password === repeatPassword : true
    }

    const validateEmail = (email) => {
        if (email.length > 0) {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        }
        else {
            return true
        }
    }

    const updateFromLocalStorage = async (user) => {
        try {
            if (localStorage.getItem('surveyId') && localStorage.getItem('surveyContent') && localStorage.getItem('surveyUserAnswers')) {
                let surveyId = parseInt(localStorage.getItem('surveyId'))
                let surveyContent = JSON.parse(localStorage.getItem('surveyContent'))
                let surveyLength = surveyContent.filter(q => q.required !== false).length
                let surveyAnswers = JSON.parse(localStorage.getItem('surveyUserAnswers'))
                if (!isNaN(surveyId)) {
                    let userAnswers = JSON.parse(localStorage.getItem('surveyUserAnswers'))
                    if (!user.mandatory_survey || surveyAnswers.length >= surveyLength) {
                        const data = { surveyId, userAnswers }
                        await postSurvey(data)
                        context.setSurveyCompleted(true)
                        localStorage.removeItem('surveyId')
                        localStorage.removeItem('surveyContent')
                    }
                }
            }
            if (localStorage.getItem('userData')) {
                let userData = JSON.parse(localStorage.getItem('userData'))

                console.log(!isEmptyNullUndefined(userData.name),
                    !isEmptyNullUndefined(userData.surname),
                    !isEmptyNullUndefined(userData.age), !isNaN(parseInt(userData.age)),
                    !isEmptyNullUndefined(userData.city),
                    !isEmptyNullUndefined(userData.gender))

                if (!isEmptyNullUndefined(userData.name) &&
                    !isEmptyNullUndefined(userData.surname) &&
                    !isEmptyNullUndefined(userData.age) && !isNaN(parseInt(userData.age)) &&
                    !isEmptyNullUndefined(userData.city) &&
                    !isEmptyNullUndefined(userData.gender)) {
                    await updateUser(userData)
                }
            }
        }
        catch (e) {
            console.error(e)
            context.setUser(user)
        }
        navigate('/')
    }

    const updateUser = async (data) => {
        try {
            const formData = new FormData();
            formData.append("name", data.name);
            formData.append("surname", data.surname);
            formData.append("age", parseInt(data.age));
            formData.append("city", data.city);
            formData.append("gender", data.gender);

            const user = await api.put("/user", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            if (user) {
                context.setUser(user)
                localStorage.removeItem('userData')
            }

        }
        catch (e) {
            console.error(e)
        }
    }

    const postSurvey = async (data) => {
        try {
            await api.post(`/survey/${data.surveyId}`, {
                content: { content: data.userAnswers }
            })
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Dialog open={open} onClose={onClose} contentStyle={{ width: '100%' }}>
            {!signUpMode &&
                <div className={styles.container}>
                    <div className={[typo.title, styles.title].join(' ')}>{t('login.title')}</div>
                    <div className={[typo.body, styles.body].join(' ')}>{t('login.subtitle')}</div>

                    <div className={styles.formContainer}>
                        <TextInput type="text" placeholder={t('login.email')} onKeyUp={onEmailChange}></TextInput>
                        <TextInput type="password" placeholder={t('login.password')} onKeyUp={onPasswordChange}></TextInput>
                        <div className={styles.resetPassword}>

                            <div className={styles.error}>
                                {error &&
                                    <span className={typo.caption}>
                                        {error.message}
                                    </span>
                                }
                            </div>

                            <Button
                                fullWidth
                                appearance="text"
                                status={loading ? ButtonStatus.Loading : ''}
                                style={{ display: 'flex', justifyContent: 'flex-end' }}
                                onClick={() => { navigate("/login") }}>
                                {t('login.forgotPassword')}
                            </Button>
                        </div>
                    </div>

                    <div className={typo.caption}>
                        Eseguendo l'accesso dichiari di aver letto e di accettare la nostra <a style={{ textDecoration: 'underlined', color: 'var(--primary)' }} href="https://shop.startingfinance.com/pages/privacy-policy" target="_blank">privacy policy</a>
                    </div>

                    <Button onClick={signIn} style={{ padding: '1rem 1.5rem' }} fullWidth>LOGIN</Button>

                    <div className={styles.textDivider}>
                        <div className={styles.divider}></div>
                        <div className={styles.caption}>{t('login.or')}</div>
                        <div className={styles.divider}></div>
                    </div>

                    <div className={styles.socialLogin}>
                        <button className={styles.googleSignIn} onClick={googleSignIn}>
                            <GoogleLogo className={styles.googleLogo}></GoogleLogo>
                            {t('login.signInWithGoogle')}
                        </button>
                        <button className={styles.appleSignIn} onClick={appleSignIn}>
                            <AppleLogo className={styles.appleLogo}></AppleLogo>
                            {t('login.signInWithApple')}
                        </button>
                    </div>
                    <div className={styles.spacer}></div>
                    <div className={styles.registerContainer}>
                        {t('login.noAccount')}
                        <Button appearance="text" onClick={() => { setSignUpMode(true) }}>{t('login.register')}</Button>
                    </div>
                </div>
            }
            {signUpMode &&
                <div className={styles.container}>
                    <div className={[typo.title, styles.title].join(' ')}>{t('signup.title')}</div>
                    <div className={[typo.body, styles.body].join(' ')}>
                        Registrati per avere un codice sconto di 50€ e per accedere ai corsi di Starting Finance, qui su Velv. Ricordati che il codice sconto vale solo per le prossime 72 ore!
                    </div>

                    <div className={styles.formContainer}>
                        <TextInput type="text" placeholder={t('signup.email')} onKeyUp={onEmailChange} autocomplete="off"></TextInput>
                        <TextInput type="password" placeholder={t('signup.password')} onKeyUp={onPasswordChange} autocomplete="new-password"></TextInput>
                        <TextInput type="password" placeholder={t('signup.repeatPassword')} onKeyUp={onPasswordRepeatChange} autocomplete="new-password"></TextInput>

                        <div className={styles.error}>
                            {error &&
                                <span className={typo.caption}>
                                    {error.message}
                                </span>
                            }
                        </div>
                    </div>

                    <div className={typo.caption}>
                        Registrandoti dichiari di aver letto e di accettare la nostra <a style={{ textDecoration: 'underlined', color: 'var(--primary)' }} href="https://shop.startingfinance.com/pages/privacy-policy" target="_blank">privacy policy</a>
                    </div>

                    <Button
                        status={loading ? ButtonStatus.Loading : ''}
                        onClick={signUp}
                        style={{ padding: '1rem 1.5rem' }}
                        fullWidth>
                        {t('signup.register').toUpperCase()}
                    </Button>

                    <div className={styles.textDivider}>
                        <div className={styles.divider}></div>
                        <div className={styles.caption}>{t('signup.or')}</div>
                        <div className={styles.divider}></div>
                    </div>

                    <div className={styles.socialLogin}>
                        <button className={styles.googleSignIn} onClick={googleSignIn}>
                            <GoogleLogo className={styles.googleLogo}></GoogleLogo>
                            {t('signup.signUpWithGoogle')}
                        </button>
                        <button className={styles.appleSignIn}>
                            <AppleLogo className={styles.appleLogo} onClick={appleSignIn}></AppleLogo>
                            {t('signup.signUpWithApple')}
                        </button>
                    </div>
                    <div className={styles.spacer}></div>
                    <div className={styles.registerContainer}>
                        {t('signup.haveAccount')}
                        <Button appearance="text" onClick={() => { setSignUpMode(false) }}>{t('signup.login')}</Button>
                    </div>

                </div>
            }
        </Dialog>
    )
}

export default LoginDialog